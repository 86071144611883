/* styles.css */
.gallery-image {
  max-width: 100%; /* Reduzir a largura para caber no contêiner */
  max-height: 300px; /* Reduzir a altura conforme necessário */
  display: block; /* Remover espaços extras ao redor da imagem */
  margin: 0 auto; /* Centralizar a imagem horizontalmente */
  object-fit: cover; /* Redimensionar a imagem mantendo a proporção */
}

/* PhotoGallery.css */

.custom-swiper {
  /* Altere a cor dos símbolos de navegação para #faa51f */
  .swiper-button-next,
  .swiper-button-prev {
    color: #faa51f;
  }

  /* Outros estilos personalizados, se necessário */
}
