.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.map {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px; /* Ajuste conforme necessário */
  margin: 0 auto; /* Isso centraliza a div horizontalmente na página */

}


.image-modal {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  position: relative;
  padding: 20px;
  text-align: center;
}

.expanded-image {
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 21px;
  background: none;
  border: none;
  color: red;
  font-size: 20px;
  cursor: pointer;
  font-size: 30px;
}
.arrow-button {
  background: transparent;
  border: none;
  font-size: 24px; /* Tamanho do símbolo de seta, ajuste conforme necessário */
  color: #000; /* Cor do símbolo de seta, ajuste conforme necessário */
  display: flex; /* Ativar flexbox para alinhar verticalmente */
  align-items: center; /* Centralizar verticalmente */
  cursor: pointer;
  padding: 0;
  margin: 0;
}

/* Estilize os botões de seta aqui, por exemplo: */
.arrow-button:hover {
  color: #ff0000; /* Altere a cor quando o mouse estiver sobre o botão */
}
.prev-button,
.next-button {
  background: transparent;
  border: none;
  font-size: 24px; /* Tamanho do símbolo de seta, ajuste conforme necessário */
  color: #fca61f; /* Cor do símbolo de seta, ajuste conforme necessário */
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  line-height: 1; /* Adicione essa linha para ajustar a altura do botão */
  padding: 10px; /* Adicione esse padding para aumentar a área de clique */
}

.prev-button {
  left: 10px;
  border-right: 3px solid #fca61f; /* Adicione uma borda à direita para criar um traço */
}

.next-button {
  right: 10px;
  border-left: 3px solid #fca61f; /* Adicione uma borda à esquerda para criar um traço */
}

/* Estilize os botões de seta aqui, por exemplo: */
.prev-button:hover,
.next-button:hover {
  color: #ff0000; /* Altere a cor quando o mouse estiver sobre o botão */
  border-color: #ff0000; /* Altere a cor da borda quando o mouse estiver sobre o botão */
}



/**/
.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;

}
/* heading */
.portfolio span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portfolio span:nth-of-type(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}

/* slider */
.portfolio .swiper{
    overflow: visible!important;
}

.portfolio-slider {
  margin-top: 3rem;
  width: 100%;
}

.portfolio-slider .swiper-slide {
  width: 20rem;
}
.portfolio img {
  width: 19rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
}

