/* styles.css */
.card-detail {
    background-color: rgba(255, 255, 255, 1); /* Cor branca totalmente opaca */
    border: 7px solid var(--orangeCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
    position: fixed;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    padding: 20px;
    overflow-y: auto;
    z-index: 999;
    /* Outros estilos personalizados, se necessário */
  }
  
/* styles.css */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close-icon {
  font-size: 24px;
  font-size: 3rem;
  color: red; /* Cor do ícone (ajuste conforme necessário) */
}
